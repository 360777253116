'use client'

import { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

export default function HomeTestimonialSection() {
    return (
        <section className=" theme-pt-40 theme-pb-40 theme-pl-30 theme-pr-30">
            <div className="auto-container">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 theme-mb-30">
                        <div className="site-heading text-left">
                            <h2>DEPOIMENTOS</h2>
                            <p>Opinião de alguns de nossos clientes</p>
                        </div>
                    </div>
                </div>
                <div className="clearfix"></div>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mr-auto ml-auto">
                        <Swiper
                            id='testimonial'
                            modules={[Navigation]}
                            autoplay={true}
                            spaceBetween={30}
                            loop={true}
                            speed={3000}
                        >
                            <div className='swiper-wrapper'>
                                <SwiperSlide className='testimonial-item'>
                                    <div className="tcontent">
                                        <blockquote>
                                            <p>Ótima loja pra se fazer bons negócios. Profissionais competentes,  educados e atenciosos. Valorizam bem o seu usado na troca, e tem ótimos carros,  revisados e com garantia pra oferecer .Super educados, atenciosos e prestativos.</p>
                                        </blockquote>
                                        <div className="testimonial-arrow-down"></div>
                                        <div className="testimonial-author">
                                            <p><strong className="font-weight-extra-bold">Robson Miranda</strong></p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className='testimonial-item'>
                                    <div className="tcontent">
                                        <blockquote>
                                            <p>Excelente experiência com a Davanti, desde o primeiro minuto que pisei na loja, até o momento de retirada do carro e pós venda. Vendedor super atencioso, gentil e claro em sanar todas as dúvidas e apoio para a compra do meu primeiro carro.</p>
                                        </blockquote>
                                        <div className="testimonial-arrow-down"></div>
                                        <div className="testimonial-author">
                                            <p><strong className="font-weight-extra-bold">Jayne Ruas</strong></p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className='testimonial-item'>
                                    <div className="tcontent">
                                        <blockquote>
                                            <p>Vendedor atencioso, cumpriu com todo o prometido, entregou no prazo certo, e quitou o meu antigo veículo que estava consignado e entrou como parte da entrada para o próximo veículo.</p>
                                        </blockquote>
                                        <div className="testimonial-arrow-down"></div>
                                        <div className="testimonial-author">
                                            <p><strong className="font-weight-extra-bold">Diego Pinheiro</strong></p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </div>
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
    );
}